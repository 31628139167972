/** @jsx jsx */
import { jsx } from '@emotion/react'
import React, { useLayoutEffect, useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { useInView } from 'react-intersection-observer'

import Footer from '../components/Footer'
import NavBar from '../components/NavBar'
import NavOverlay from '../components/NavOverlay'
import Layout from '../components/Layout'
import ContactForm from '../components/ContactForm'
import Seo from '../components/seo'

import GithubSVG from '../assets/icons/github.svg'
import TwitterSVG from '../assets/icons/twitter.svg'

import { Body1, Headline2, Headline4 } from '../components/Typography'

const researchTopics = [
  'our favourite GIFs',
  'new coffee gear',
  'mechanical keyboards',
  'brain pills',
  'sacred geometry',
]

const GithubIcon = props => {
  const StyledGithub = styled(GithubSVG)`
    width: 24px;
    height: 24px;
    display: inline-block;
    margin-right: 23px;

    path {
      fill: var(--terminal-500);
    }
  `

  return (
    <a href="https://github.com/SuperGoodSoft">
      <StyledGithub {...props} />
    </a>
  )
}

const TwitterIcon = props => {
  const StyledTwitter = styled(TwitterSVG)`
    width: 24px;
    height: 24px;
    display: inline-block;

    path {
      fill: var(--terminal-500);
    }
  `

  return (
    <a href="https://twitter.com/supergoodsoft">
      <StyledTwitter {...props} />
    </a>
  )
}

const HeroBackgroundWrapper = styled.div`
  background: linear-gradient(90.9deg, #2e60ff 0%, rgba(46, 96, 255, 0) 99.36%),
    #0000fe;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: ${props => (props.isOverlayOpen ? 'hidden' : 'unset')};
  opacity: ${props => (props.isOverlayOpen ? '0' : 'unset')};
`

const GradientBackgroundWrapper = styled.div`
  background: linear-gradient(90.9deg, #2e60ff 0%, rgba(46, 96, 255, 0) 99.36%),
    #0000fe;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: ${props => (props.isOverlayOpen ? 'hidden' : 'unset')};
  opacity: ${props => (props.isOverlayOpen ? '0' : 'unset')};
`

const WhiteBackgroundWrapper = styled.div`
  height: auto;
  clip-path: inset(0 0 0 0);
`

const ContactFormWrapper = styled.div`
  ${mq({
    gridColumnStart: ['1', '1', '2', '2', '2'],
    gridColumnEnd: ['5', '9', '12', '12', '12'],
  })};
  margin-bottom: -400px;
  z-index: 1;
  position: relative;
`

const HeroText = styled.h1`
  ${Headline2};
  color: var(--terminal-0);
  margin-block-start: 0;
  margin-block-end: 0;
  display: inline;
`

const HeroWrapper = styled.div`
  padding-right: 12px;
  padding-bottom: 6px;
  overflow: hidden;
  ${mq({
    gridColumnStart: ['1', '1', '1', '1', '1'],
    gridColumnEnd: ['5', '9', '13', '13', '13'],
  })};
`

const InnerHeroWrapper = styled.div`
  position: relative;
  animation-delay: 10s;
  visibility: hidden;

  @keyframes grow-in-steps {
    0% {
      visibility: hidden;
    }
    100% {
      bottom: 0;
      visibility: visible;
    }
  }
`

const HeroSpan = styled.span`
  color: ${props => (props.blue ? 'var(--screen-300)' : 'var(--terminal-0)')};

  .crossed-out {
    color: var(--screen-400);
    text-decoration: line-through 5px;
    display: inline-block;
  }
`

const Spacer = styled.div`
  height: 400px;
  grid-column: 1 /-1;
`

const ContactText = styled.div`
  text-align: center;
  ${mq({
    gridColumn: ['1 / -1', '1 / -1', '5 / 9', '5 / 9', '5 / 9'],
  })};

  h4 {
    ${Headline4};
    color: var(--terminal-500);
  }

  p {
    ${Body1};
    color: var(--terminal-400);
  }

  a {
    text-decoration: none;
  }
`

function ContactPage() {
  const [isOverlayOpen, setIsOverlayOpen] = useState(false)
  const [pixels, setPixels] = useState(0)
  const [contactFormRef, contactFormInView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  })
  const [randomResearchTopic, setRandomResearchTopic] = useState('')
  const pageTitle = 'Contact Us'

  const ref = useRef()

  useEffect(() => {
    window.alreadyAnimated = true
  }, [])

  useLayoutEffect(() => {
    const whiteText = document.getElementById('white')
    const blueText = document.getElementById('blue')

    const numLines =
      whiteText.getClientRects().length + blueText.getClientRects().length
    const height = ref.current.offsetHeight

    if (window.alreadyAnimated) {
      ref.current.style.display = 'block'
      ref.current.style.bottom = 'unset'
      ref.current.style.visibility = 'visible'
    } else {
      ref.current.style.display = 'block'
      ref.current.style.bottom = `${-height}px`
      ref.current.style.animation = `grow-in-steps ${numLines /
        4}s steps(${numLines}, start) forwards 0.5s`
    }

    setRandomResearchTopic(
      researchTopics[Math.floor(Math.random() * researchTopics.length)]
    )
  }, [])

  return (
    <React.Fragment>
      <Seo
        title={pageTitle}
        description="To learn more about our development team, contact us by email or filling out our form."
        keywords={[
          `Ruby on Rails`,
          `Rails`,
          `React`,
          `Spree`,
          `Solidus`,
          `eCommerce`,
        ]}
      />
      <NavBar
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
        pixels={pixels}
        setPixels={setPixels}
        theme={'blue'}
        invertedBelow={true}
        title={pageTitle}
      />
      <NavOverlay
        isOverlayOpen={isOverlayOpen}
        setIsOverlayOpen={setIsOverlayOpen}
      />
      <HeroBackgroundWrapper isOverlayOpen={isOverlayOpen}>
        <Layout>
          <HeroWrapper>
            <InnerHeroWrapper ref={ref}>
              <HeroText>
                <HeroSpan id="white">Is it time? Let’s talk.</HeroSpan>
                <br />
                <HeroSpan blue={true} id="blue">
                  We’re likely at our computers right now, researching{' '}
                  <div className={'crossed-out'}>{randomResearchTopic}</div>{' '}
                  important web development stuff.
                </HeroSpan>
              </HeroText>
            </InnerHeroWrapper>
          </HeroWrapper>
        </Layout>
      </HeroBackgroundWrapper>
      <GradientBackgroundWrapper isOverlayOpen={isOverlayOpen}>
        <Layout>
          <ContactFormWrapper ref={contactFormRef}>
            {contactFormInView ? <ContactForm /> : null}
          </ContactFormWrapper>
        </Layout>
      </GradientBackgroundWrapper>
      <WhiteBackgroundWrapper>
        <NavBar
          isOverlayOpen={isOverlayOpen}
          setIsOverlayOpen={setIsOverlayOpen}
          pixels={pixels}
          setPixels={setPixels}
          theme={'day'}
          invertedAbove={true}
          title={pageTitle}
        />
        <Layout>
          <Spacer />
          <ContactText>
            <h4>
              We're a remote-first team with <br />
              headquarters in Victoria, Canada
            </h4>
            <p>
              2031 Store Street <br />
              Victoria, BC, Canada, V8T 5L9 <br />
              <a href="mailto:contact@super.gd">contact@super.gd</a>
            </p>
            <GithubIcon />
            <TwitterIcon />
          </ContactText>
          <Footer copyrightYear={'2021'} />
        </Layout>
      </WhiteBackgroundWrapper>
    </React.Fragment>
  )
}

export default ContactPage
